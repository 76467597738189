.agent-status-box .ant-switch {
  @apply bg-[#BAC3D1];
}

.agent-status-box .ant-switch:hover {
  @apply bg-[#BAC3D1];
}

.agent-status-box .ant-switch.ant-switch-checked {
  @apply bg-green-emerald;
}
