.advance-settings .ant-collapse-item .ant-collapse-header {
  @apply px-0 !important;
}

.advance-settings .ant-collapse-content-box {
  @apply p-0 !important;
}

#modified-textfield fieldset {
  display: none !important;
}

#modified-textfield input {
  padding-top: 2px;
  padding-right: 60px;
  text-align: right;
  vertical-align: middle;
  min-width: 100px !important;
  border: solid #d9d9d9;
  border-radius: 8px;
  border-width: 1px;
  height: 38px;
}

#modified-textfield > div > div > div {
  min-width: 100px !important;
  max-width: 100px !important;
  padding: 0;
  padding-right: 10px;
}
