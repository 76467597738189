.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  @apply border-none bg-green-emerald !important;
}

.ant-list-item-meta-title {
  @apply mt-2;
}

.customer-form label {
  @apply text-base font-semibold leading-6 !important;
}

.customer-form .ant-form-item {
  @apply w-full !important;
}

.customer-form .ant-select-selector {
  @apply h-[51px] rounded-xl border border-solid border-[#949494] py-[10px] !important;
}

.customer-form .ant-select-selection-placeholder {
  @apply text-base !important;
}

.customer-form .ant-form-item-label {
  @apply text-start !important;
}

.customer-form input {
  @apply w-full rounded-xl border border-solid border-[#949494] p-3 text-base font-normal !important;
}

.customer-form textarea {
  @apply w-full overflow-hidden rounded-xl border border-solid border-[#949494] p-3 font-['Inter'] text-base font-normal leading-6;
}

.customer-form button {
  @apply flex h-12 w-full cursor-pointer items-center justify-center self-stretch rounded-3xl py-3 text-base font-semibold text-white;
}

.customer-form .ant-input-group .ant-select {
  @apply mr-2 !important;
}

.customer-form .ant-input-group .ant-select .ant-select-selector {
  @apply px-4 py-3 !important;
}

.custom-label {
  @apply mb-2 text-base font-semibold leading-6 !important;
}

.workspace-customer-files .ant-checkbox .ant-checkbox-inner {
  border-color: #313131;
}

.workspace-customer-files .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1a936f;
  border-color: #1a936f;
}

.ant-form-item-required {
  @apply h-auto !important;
}

.team-action-button {
  @apply mt-4 flex cursor-pointer flex-row items-center justify-center rounded-full border-solid text-base font-semibold transition-all duration-75 hover:bg-grey-10 active:bg-grey-20;
}

.team-action-confirm-button {
  @apply mt-4 flex flex-row items-center justify-center rounded-full border-solid bg-green-emerald p-3 text-base font-semibold text-white transition-all duration-75 hover:cursor-pointer hover:bg-green-emerald-light active:bg-green-emerald;
}

.click-animation {
  @apply transition-all duration-100 ease-out hover:cursor-pointer hover:bg-grey-20 active:scale-95 active:bg-grey-30 !important;
}

.error-alert {
  @apply border border-solid border-grey-20 bg-white;
}

.zoom {
  zoom: 75%;
}

.work-schedule .ant-collapse-header {
  @apply p-0 !important;
}

.work-schedule .ant-collapse-content-box {
  @apply p-0 !important;
}

.manual-kyc-btn {
  @apply h-8 border border-solid bg-transparent outline-none transition-all duration-75 xl:h-12 !important;
}

.manual-kyc-p {
  @apply m-0 p-0 text-black;
}

.ant-pagination-item-active {
  @apply border-none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @apply border-none bg-green-emerald !important;
}

.account-list-panel .ant-collapse-header {
  overflow-wrap: anywhere;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-width: 0;
  border-bottom-color: #1677ff;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom-width: 0;
  color: #282c34;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected:hover::after {
  border-bottom-width: 0;
  color: #282c34;
}

/* Custom Table Styles */
.analytics-table .ant-table-column-sorter {
  display: none !important;
}

.analytics-table .ant-table-thead th,
.analytics-table .ant-table-thead td {
  background: transparent !important;
}

/* Typography */
h2.heading {
  @apply text-xl font-semibold lg:text-2xl;
}

h3.heading {
  @apply text-base font-semibold lg:text-xl;
}

h4.heading {
  @apply text-sm font-semibold lg:text-base;
}

h5.heading {
  @apply text-xs font-semibold lg:text-sm;
}

/* Scrollbar */
.scrollbar-main::-webkit-scrollbar {
  display: block;
  height: 3px;
  z-index: 9999;
}

.scrollbar-main::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-main::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
}

.scrollbar-main::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.scrollbar-main::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.custom-tooltip .ant-pagination-item {
  display: none !important;
}

.custom-tooltip .ant-tooltip-inner {
  border-radius: 1rem;
}

.image-zoom button {
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}
