.ant-menu-item {
  @apply pl-1 text-sm !important;
}
.ant-menu-item.ant-menu-item-selected {
  @apply rounded-none border-0 border-b-4 border-solid border-bold-green bg-transparent font-semibold !important;
}
.ant-menu-item:hover {
  @apply bg-transparent transition-all !important;
}

.sidebar-v2 {
  height: calc(100% - 64px);
}
